<template src="./AcademicAllCoursesUniversityTemplate.html">

</template>

<script>
import coursesService from "@/services/Courses";
import {mapGetters} from "vuex";
import certificateTemplateService from "@/services/CertificateTemplate";

export default {
  name: "AcademicAllCoursesUniversityTemplate",
  data() {
    return {
      listCourses: [],
      selectedItemIndex: 0,
      courseData: null,
    };
  },
  methods: {

    async getCourse(courseId) {
      try {
        this.courseData = await coursesService.getCourseById(courseId);
      } catch (e) {
        console.log('e', e);
      }
    },
    async getAllCourse() {
      try {
        this.listCourses = await certificateTemplateService.certificateTemplateByInstitution(this.user.institutionId.id)
        if (this.$route.params.certificateTemplateId) {
          this.listCourses.map((element, index) => {
            if (element.id === this.$route.params.certificateTemplateId) this.selectedItemIndex = index;
          })
        }
      } catch (e) {
        console.log('e', e);
      }
    },
    async linkCertificateToCourse(certificateTemplateId) {
      try {
        const data = {
          courseId: this.$route.params.courseId,
          certificateTemplateId
        }
        if(this.$route.params.certificateTemplateId) await certificateTemplateService.updateCertificateTemplateCourse(data, this.$route.params.linkCertificateTemplateId);
        else await certificateTemplateService.createCertificateTemplateCourse(data);
        await this.$router.push('/AcademicAllCoursesUniversity');
      } catch (e) {
        console.log('e', e);
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
  mounted() {
    this.getAllCourse();
  },

};
</script>

<style scoped lang="scss" src="./AcademicAllCoursesUniversityTemplate.scss">

</style>
